import axios, { CancelToken } from 'axios';
let source = CancelToken.source();

export default function service (opts) {
  const config = {
    method: opts.method || 'get',
    url: `https://accounts.animetv.rocks/${opts.route}`,
    params: !opts.data ? {
      ...opts.params,
      locale: opts.locale
    } : null,
    data: opts.data,
    cancelToken: !opts.noCancel ? source.token : null
  }

  return axios(config)
}

export function cancelCurrentRequests () {
  source.cancel('User changed page')
  source = CancelToken.source()
}
