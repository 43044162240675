import {
  ADD_SERIES,
  ADD_ANILIST_ITEM,
  ADD_EPISODES,
  // ADD_MAL_ITEM,
  ADD_LOG_ITEM,
  SET_ERROR,
  SET_BROWSE,
  SET_MY_LIST,
  SET_RECENT,
  SET_POPULAR_SERIES,
  SET_ALL_TIME_BEST,
  SET_PLAYHEAD_TIME,
  SET_QUEUE,
  SET_HISTORY,
  SET_UPCOMING_PREMIERE,
  SET_LAST_AIRED,
  SET_TOP_AIRED,
  SET_SEARCH_IDS,
  ADD_COLLECTION_BULK,
} from '../actions';

export const addToObj = (state, key, data) => ({
  ...state,
  [key]: {
    ...state[key],
    ...data
  }
})

export default function Data (state = {
  searchIds: [],
  search: [],
  media: [],
  history: {
    offset: 0,
    data: [],
    finished: false
  },
  queue: {
    offset: 0,
    data: [],
    finished: false
  },
  logs: [],
  series: {},
  episodes: {},
  recent: [],
  browse: [],
  my_list: [],
  popular: [],
  all_time_best: [],
  top_last_aired: [],
  top_aired_fanarts: [],
  upcoming_premiere: [],
  languages: [],
  genres: [],
  mal: {},
  anilist: {},
  error: false
}, action) {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        error: action.payload
      }
    case SET_SEARCH_IDS:
      return {
        ...state,
        searchIds: action.payload
      }
    case ADD_COLLECTION_BULK:
      return addToObj(state, 'search', action.payload)
    case ADD_SERIES:
      return addToObj(state, 'series', action.payload)
    case ADD_EPISODES:
      return addToObj(state, 'episodes', action.payload)
    case ADD_LOG_ITEM:
      return {
        ...state,
        logs: state.logs.concat(action.payload)
      }
    case SET_BROWSE:
      return {
        ...state,
        browse: action.payload
      }
    case SET_MY_LIST:
      return {
        ...state,
        my_list: action.payload
      }
    case SET_RECENT:
      return {
        ...state,
        recent: action.payload
      }
    case SET_PLAYHEAD_TIME:
      return {
        ...state,
        media: action.payload,
    }
    case SET_QUEUE:
      return {
        ...state,
        queue: {
          offset: action.payload.offset,
          data: action.payload.queue,
          finished: action.payload.finished
        },
    }
    case SET_HISTORY:
      return {
        ...state,
        history: {
          offset: action.payload.offset,
          data: action.payload.history,
          finished: action.payload.finished
        }
      }
    case SET_POPULAR_SERIES:
      return {
        ...state,
        popular: action.payload
      }
    case SET_ALL_TIME_BEST:
      return {
        ...state,
        all_time_best: action.payload
      }
    case SET_LAST_AIRED:
      return {
        ...state,
        top_last_aired: action.payload
      }
    case SET_TOP_AIRED:
      return {
        ...state,
        top_aired_fanarts: action.payload
      }
    case SET_UPCOMING_PREMIERE:
      return {
        ...state,
        upcoming_premiere: action.payload
      }
    // case ADD_MAL_ITEM:
    //   return {
    //     ...state,
    //     mal: {
    //       ...state.mal,
    //       [action.payload.id]: action.payload.data
    //     }
    //   }
    case ADD_ANILIST_ITEM:
      return {
        ...state,
        anilist: {
          ...state.anilist,
          [action.payload.id]: action.payload.data
        }
      }
    default:
      return state
  }
}
