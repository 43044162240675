import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Switch, Redirect, Route, withRouter } from 'react-router-dom';
import { isLoggedIn } from './lib/util';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faTv,
  faSync,
  faCalendar,
  faClock,
  faSearch,
  faListOl,
  faFastForward,
  faCaretRight,
  faCaretLeft,
  faChevronRight,
  faChevronLeft,
  faCaretDown,
  faExclamationTriangle,
  faStar,
  faEye,
  faNewspaper,
  faBookmark,
  faMask,
  faCheckCircle,
  faTimesCircle,
  faCertificate,
  faClosedCaptioning,
  faHistory,
  faList,
  faStepForward,
  faStepBackward,
  faUser,
  faCalendarAlt,
  faCog,
  faPlus,
  faMinus,
  faInfo,
  faCircleNotch,
  faCrown,
  faBook,
  faPlay,
  faPause,
  faCompress,
  faExpand,
  faQuestion,
  faVolumeUp,
  faVolumeDown,
  faVolumeMute,
  faVolumeOff,
  faClone,
  faArrowLeft,
  faVideo,
  faDesktop,
  faHourglassHalf,
  faCompass,
  faLandmark,
  faHashtag,
  faGlobe,
  faUsers,
  faTimes,
  faBell,
  faBars,
  faTh
} from '@fortawesome/free-solid-svg-icons';

import './App.scss';
import './themes/light.scss';
import './themes/dark.scss';

// you don't see anything
const faVolume = {
  prefix: 'fas',
  iconName: 'volume',
  icon: [480, 512, [], 'f028', 'M215.03 71.05L126.06 160H24c-13.26 0-24 10.74-24 24v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c15.03 15.03 40.97 4.47 40.97-16.97V88.02c0-21.46-25.96-31.98-40.97-16.97zM480 256c0-63.53-32.06-121.94-85.77-156.24-11.19-7.14-26.03-3.82-33.12 7.46s-3.78 26.21 7.41 33.36C408.27 165.97 432 209.11 432 256s-23.73 90.03-63.48 115.42c-11.19 7.14-14.5 22.07-7.41 33.36 6.51 10.36 21.12 15.14 33.12 7.46C447.94 377.94 480 319.53 480 256zm-141.77-76.87c-11.58-6.33-26.19-2.16-32.61 9.45-6.39 11.61-2.16 26.2 9.45 32.61C327.98 228.28 336 241.63 336 256c0 14.38-8.02 27.72-20.92 34.81-11.61 6.41-15.84 21-9.45 32.61 6.43 11.66 21.05 15.8 32.61 9.45 28.23-15.55 45.77-45 45.77-76.88s-17.54-61.32-45.78-76.86z']
}

library.add(
  faTv,
  faSync,
  faCalendar,
  faClock,
  faSearch,
  faListOl,
  faFastForward,
  faCaretRight,
  faCaretLeft,
  faChevronRight,
  faChevronLeft,
  faCaretDown,
  faExclamationTriangle,
  faStar,
  faEye,
  faNewspaper,
  faBookmark,
  faMask,
  faCheckCircle,
  faTimes,
  faTimesCircle,
  faCertificate,
  faClosedCaptioning,
  faHistory,
  faList,
  faStepForward,
  faStepBackward,
  faUser,
  faCalendarAlt,
  faCog,
  faPlus,
  faMinus,
  faInfo,
  faCircleNotch,
  faCrown,
  faBook,
  faPlay,
  faPause,
  faCompress,
  faExpand,
  faQuestion,
  faVolumeUp,
  faVolumeMute,
  faVolumeDown,
  faVolumeOff,
  faVolume,
  faClone,
  faArrowLeft,
  faVideo,
  faDesktop,
  fab,
  faHourglassHalf,
  faCompass,
  faLandmark,
  faHashtag,
  faGlobe,
  faUsers,
  faBell,
  faBars,
  faTh)

const AppContainer = React.lazy(() => import('./Components/AppContainer'));
const Logs = React.lazy(() => import('./pages/Logs'));
const Login = React.lazy(() => import('./pages/Login'));
const Profile = React.lazy(() => import('./pages/Profile'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const AuthedRoute = React.lazy(() => import('./Components/AuthedRoute'));
const Browse = React.lazy(() => import('./pages/Browse'));
const MyList = React.lazy(() => import('./pages/MyList'));
const Recent = React.lazy(() => import('./pages/Recent'));
const LastAired = React.lazy(() => import('./pages/LastAired'));
const Popular = React.lazy(() => import('./pages/Popular'));
const BestSeries = React.lazy(() => import('./pages/BestSeries'));
const UpcomingPremiere = React.lazy(() => import('./pages/UpcomingPremiere'));
const Series = React.lazy(() => import('./pages/Series'));
const Search = React.lazy(() => import('./pages/Search'));
const Media = React.lazy(() => import('./pages/Media'));
const History = React.lazy(() => import('./pages/History'));
const Queue = React.lazy(() => import('./pages/Queue'));
const Loading = React.lazy(() => import('./Components/Loading/Loading'));

const App = ({ Auth }) => {
  const authed = isLoggedIn(Auth, false)
  return (
      <AppContainer>
        <Switch>
          <Route exact path='/' component={Dashboard} />
          <AuthedRoute exact path='/login' redirect='/' authed={!authed} component={Login} />
          <AuthedRoute path='/profile' authed={authed} component={Profile} />
          <AuthedRoute path='/logs' authed={authed} component={Logs} />
          <Route path='/browse' component={Browse} />
          <AuthedRoute path='/my-list' authed={authed} component={MyList} />
          <AuthedRoute path='/queue' authed={authed} component={Queue} />
          <AuthedRoute path='/history' authed={authed} component={History} />
          <AuthedRoute path='/recent' authed={authed} component={Recent} />
          <AuthedRoute path='/watch/:id/:slug/:episodeId/:episodeNumber' authed={authed} component={Media} />
          <AuthedRoute path='/last-aired' authed={authed} component={LastAired} />
          <AuthedRoute path='/popular' authed={authed} component={Popular} />
          <AuthedRoute path='/all-time' authed={authed} component={BestSeries} />
          <AuthedRoute path='/premiere-upcoming' authed={authed} component={UpcomingPremiere} />
          <Route path='/anime/:id/:slug?' component={Series} />
          <Route path='/search/:query?' component={(props) => <Search query={encodeURI(props.match.params.query)} {...props} />} />
          <Route path='/empty' component={() => <Loading />} />
          <Redirect from="*" to="/" />
        </Switch>
      </AppContainer>
  );
}

export default compose(
  withRouter,
  connect((store) => {
    return {
      Auth: store.Auth
    }
  })
)(App)
