import { applyMiddleware, createStore, compose } from 'redux';
import localForage from './localForage';
import { createBrowserHistory } from 'history';
import { persistCombineReducers, persistStore, createMigrate } from 'redux-persist';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import reducers from '../reducers';
import {Api} from 'fairlight';

/* eslint-disable */
export default function configureStore(preloadedState) {
  const middleware = [thunk]

  const migrations = { // migrations for store changes
    1: (state) => {
      // placeholder until there is the need for an actual one
      return {
        ...state
      }
    }
  }
  
  const api = new Api({baseUrl: 'https://poseidon.animetv.rocks/api'})
  const storage = localForage.init()
  const persistorConfig = { // only persist auth
    version: 1,
    storage,
    key: 'animetv',
    whitelist: ['Auth', 'Options'],
    migrate: createMigrate(migrations, { debug: false })
  }

  const history = createBrowserHistory()
  const createReducer = (history) => persistCombineReducers(persistorConfig, {
    router: connectRouter(history),
    ...reducers
  })

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    createReducer(history),
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        thunk.withExtraArgument({api}),
        ...middleware
      )
    )
  )
  const persistor = persistStore(store)
  return { store, persistor, history, api }
}
