import axios, { isCancel } from 'axios';
import { matchPath } from 'react-router';
import { batch } from 'react-redux';
import { startSession } from './Auth';
import { removeAniList, removeSimkl } from './Auth';
import { push, replace } from 'connected-react-router';

export const handleError = async (err, dispatch, state, reject) => {
  const path = state.router.location.pathname || '/'
  if (!isCancel(err)) {
    const { data } = err
    if (data) {
      switch (data.code) {
        case 'bad_session': { // when the session has expired?
          // create a new session
          let sessionId
          try {
            sessionId = await dispatch(startSession())
          } catch (e) {
            window.location.reload()
          }

          // move to an empty page and then back if not on the media page
          const isMediaPage = matchPath(path, { path: '/watch/:id/:slug/:episodeId/:episodeNumber', exact: true })
          if (!isMediaPage) {
            if (sessionId) {
              dispatch(push('/empty'))
              dispatch(replace(path))
            } else {
              batch(() => {
                dispatch(removeAniList())
                dispatch(removeSimkl())
              })
              dispatch(push('/'))
            }
          }
          break
        }
        case 'bad_request': { // when removed from the devices and the like
          batch(() => {
            dispatch(removeAniList())
            dispatch(removeSimkl())
          })
          dispatch(setError(data.code))
          dispatch(push('/login'))
          break
        }
        default: {
          dispatch(setError(true))
          reject(err)
          break
        }
      }
    } else {
      dispatch(setError(true))
    }
  } else {
    console.error('Cancelled request.')
  }
}

export const SET_ERROR = 'SET_ERROR'
export const setError = (error) => ({
  type: SET_ERROR,
  payload: error
})

export const SET_SEARCH_IDS = 'SET_SEARCH_IDS'
export const setSearchIds = (ids) => ({
  type: SET_SEARCH_IDS,
  payload: ids
})

export const SET_BROWSE = 'SET_BROWSE'
export const setBrowseList = (list) => ({
  type: SET_BROWSE,
  payload: list
})

export const SET_MY_LIST = 'SET_MY_LIST'
export const setMyList = (list) => ({
  type: SET_MY_LIST,
  payload: list
})

export const SET_RECENT = 'SET_RECENT'
export const setRecent = (recent) => ({
  type: SET_RECENT,
  payload: recent
})

export const SET_ALL_TIME_BEST = 'SET_ALL_TIME_BEST'
export const setAllTimeBest = (all_time_best) => ({
  type: SET_ALL_TIME_BEST,
  payload: all_time_best
})

export const SET_LAST_AIRED = 'SET_LAST_AIRED'
export const setLastAired = (top_last_aired) => ({
  type: SET_LAST_AIRED,
  payload: top_last_aired
})

export const SET_TOP_AIRED = 'SET_TOP_AIRED'
export const setTopAired = (top_aired_fanarts) => ({
  type: SET_TOP_AIRED,
  payload: top_aired_fanarts
})

export const SET_POPULAR_SERIES = 'SET_POPULAR_SERIES'
export const setPopularSeries = (popular) => ({
  type: SET_POPULAR_SERIES,
  payload: popular
})

export const SET_UPCOMING_PREMIERE = 'SET_UPCOMING_PREMIERE'
export const setUpcomingPremiere = (upcoming_premiere) => ({
  type: SET_UPCOMING_PREMIERE,
  payload: upcoming_premiere
})

export const SET_PLAYHEAD_TIME = 'SET_PLAYHEAD_TIME'
export const setPlayheadTime = (play_data) => ({
  type: SET_PLAYHEAD_TIME,
  payload: play_data
})

export const SET_QUEUE = 'SET_QUEUE'
export const setQueue = (offset, queue, finished) => ({
  type: SET_QUEUE,
  payload: {
    offset,
    queue,
    finished
  }
})

export const SET_HISTORY = 'SET_HISTORY'
export const setHistory = (offset, history, finished) => ({
  type: SET_HISTORY,
  payload: {
    offset,
    history,
    finished
  }
})

// export const ADD_MAL_ITEM = 'ADD_MAL_ITEM'
// export const addMalItem = (id, data) => ({
//   type: ADD_MAL_ITEM,
//   payload: {
//     id,
//     data
//   }
// })

export const ADD_ANILIST_ITEM = 'ADD_ANILIST_ITEM'
export const addAniListItem = (id, data) => ({
  type: ADD_ANILIST_ITEM,
  payload: {
    id,
    data
  }
})

export const addIdArr = (id, arr, action) => ({
  type: action,
  payload: {
    [id]: arr
  }
})

export const ADD_SERIES = 'ADD_SERIES'
export const addSeries = (id, obj) => addIdArr(id, obj, ADD_SERIES)

export const ADD_COLLECTION_BULK = 'ADD_COLLECTION_BULK'
export const addCollectionBulk = (id, bulk) => addIdArr(id, bulk, ADD_COLLECTION_BULK)

export const ADD_EPISODES = 'ADD_EPISODES'
export const addEpisodes = (id, obj) => addIdArr(id, obj, ADD_EPISODES)

export const ADD_LOG_ITEM = 'ADD_LOG_ITEM'
export const addLogItem = (date, logObj, errorType) => ({
  type: ADD_LOG_ITEM,
  payload: {  date, logObj, errorType }
})

export const getBrowseList = (genre, type, network, year, sort, page) => (dispatch, getState, { api }) => {
  const state = getState()
  return new Promise(async (resolve, reject) => {
    try {
      await api.request({
        url: `/browse?genre=${genre}&type=${type}&network=${network}&year=${year}&sort=${sort}&page=${page}`,
        method: 'GET',
        responseType: 'json',
        successCodes: [200]
      }, {
        fetchPolicy: 'cache-and-fetch',
        deduplicate: true
      }).then(data => {
        dispatch(setBrowseList(data))
        resolve()
      }).catch(error => {
        reject(new Error(error))
      })
    } catch (err) {
      await handleError(err, dispatch, state, reject)
    }
  })
}

export const getMyList = (token) => (dispatch, getState, { api }) => {
  const state = getState()
  if (state.Data.my_list.length > 0) return Promise.resolve()
  return new Promise(async (resolve, reject) => {
    try {
      await api.request({
        url: `/simkl_my_list`,
        method: 'POST',
        body: JSON.stringify({ access_token: `${token}` }),
        responseType: 'json',
        successCodes: [200]
      }, {
        fetchPolicy: 'cache-and-fetch',
        deduplicate: true
      }).then(data => {
        dispatch(setMyList(data.anime));
        resolve();
      }).catch(error => {
        reject(new Error(error))
      })
    } catch (err) {
      await handleError(err, dispatch, state, reject)
    }
  })
}

export const getPlaybackTime = (anime_id) => (dispatch, getState) => {
  const state = getState()
  if (!state.Auth.token) {
    return Promise.resolve()
  }
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await axios({
        method: 'POST',
        url: `https://accounts.animetv.rocks/anime/playback/${anime_id}`,
        headers: {
          'Access-Control-Allow-Origin' : '*',
          'access-token': `${state.Auth.token}`
        }
      });
      if (resp.data.error) throw resp
      dispatch(setPlayheadTime(resp.data))
      resolve()
    } catch (err) {
      await handleError(err, dispatch, state, reject)
    }
  })
}

export const getQueue = ({limit = 6, offset = 0} = {}, append = false, force = false) => (dispatch, getState) => {
  const state = getState()
  // handle appending offset
  offset = append ? state.Data.queue.offset + limit : offset
  if (!state.Auth.token) return Promise.resolve()
  if (state.Data.queue && state.Data.queue.data.length > 0 && !force && !append) return Promise.resolve()
  if (state.Data.queue.finished) return Promise.resolve()
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await axios({
        method: 'POST',
        url: `https://accounts.animetv.rocks/anime/queue`,
        data: ({
          limit,
          offset
        }),
        headers: {
          'Access-Control-Allow-Origin' : '*',
          'access-token': `${state.Auth.token}`
        }
      });
      if (resp.data.error) throw resp

      const data = resp.data
      if (!append) {
        // overwrite the queue data
        dispatch(setQueue(offset, data, data.length > 0 ? false : true))
      } else {
        // append to the queue data
        dispatch(setQueue(offset, [
          ...state.Data.queue.data,
          ...data
        ], data.length > 0 ? false : true))
      }
      resolve()
    } catch (err) {
      await handleError(err, dispatch, state, reject)
    }
  })
}

export const getHistory = ({limit = 24, offset = 0} = {}, append = false, force = false) => (dispatch, getState) => {
  const state = getState()
  // handle appending offset
  offset = append ? state.Data.history.offset + limit : offset
  if (!state.Auth.token) return Promise.resolve()
  if (state.Data.history && state.Data.history.data.length > 0 && !force && !append) return Promise.resolve()
  if (state.Data.history.finished) return Promise.resolve()
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await axios({
        method: 'POST',
        url: `https://accounts.animetv.rocks/anime/history`,
        data: ({
          limit,
          offset
        }),
        headers: {
          'Access-Control-Allow-Origin' : '*',
          'access-token': `${state.Auth.token}`
        }
      });
      if (resp.data.error) throw resp

      const data = resp.data
      if (!append) {
        // overwrite the data there
        dispatch(setHistory(offset, data, data.length > 0 ? false : true))
      } else {
        // append to the list of data already there
        dispatch(setHistory(offset, [
          ...state.Data.history.data,
          ...data
        ], data.length > 0 ? false : true))
      }
      resolve()
    } catch (err) {
      await handleError(err, dispatch, state, reject)
    }
  })
}

export const updatePlaybackTime = (playhead, anime_id, anime_slug, episode_id, episode_number, isFinished, anime_title, episode_title, episode_description, isMovie, runtime, thumbnail) => (dispatch, getState) => {
  const state = getState()
  if (!state.Auth.token) {
    return Promise.resolve()
  }
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await axios({
        method: 'POST',
        url: `https://accounts.animetv.rocks/anime/playback/update/${anime_id}/${anime_slug}/${episode_id}/${episode_number}`,
        data: ({
          playhead,
          isFinished,
          anime_title,
          episode_title,
          episode_description,
          isMovie,
          runtime,
          thumbnail
        }),
        headers: {
          'Access-Control-Allow-Origin' : '*',
          'access-token': `${state.Auth.token}`
        }
      });
      if (resp.data.error) throw resp
      resolve()
    } catch (err) {
      await handleError(err, dispatch, state, reject)
    }
  })
}

export const getRecent = () => (dispatch, getState, { api }) => {
  const state = getState()
  if (state.Data.recent.length > 0) return Promise.resolve()
  return new Promise(async (resolve, reject) => {
    try {
      await api.request({
        url: '/latest_schedule',
        method: 'GET',
        responseType: 'json',
        successCodes: [200]
      }, {
        fetchPolicy: 'cache-and-fetch',
        deduplicate: true
      }).then(data => {
        if (data.new_episodes) {
          dispatch(setRecent(data.new_episodes))
          resolve()
        } else {
          reject(new Error('No data'))
        }
      }).catch(error => {
        reject(new Error(error))
      })
    } catch (err) {
      await handleError(err, dispatch, state, reject)
    }
  })
}

export const getAllTimeBest = () => (dispatch, getState, { api }) => {
  const state = getState()
  if (state.Data.all_time_best.length > 0) return Promise.resolve()
  return new Promise(async (resolve, reject) => {
    try {
      await api.request({
        url: '/all_time_best',
        method: 'GET',
        responseType: 'json',
        successCodes: [200]
      }, {
        fetchPolicy: 'cache-and-fetch',
        deduplicate: true
      }).then(data => {
        dispatch(setAllTimeBest(data))
        resolve()
      }).catch(error => {
        reject(new Error(error))
      })
    } catch (err) {
      await handleError(err, dispatch, state, reject)
    }
  })
}

export const getTopLastAired = () => (dispatch, getState, { api }) => {
  const state = getState()
  if (state.Data.top_last_aired.length > 0) return Promise.resolve()
  return new Promise(async (resolve, reject) => {
    try {
      await api.request({
        url: '/latest_schedule',
        method: 'GET',
        responseType: 'json',
        successCodes: [200]
      }, {
        fetchPolicy: 'cache-and-fetch',
        deduplicate: true
      }).then(data => {
        dispatch(setLastAired(data.top_last_aired))
        resolve()
      }).catch(error => {
        reject(new Error(error))
      })
    } catch (err) {
      await handleError(err, dispatch, state, reject)
    }
  })
}

export const getTopAired = () => (dispatch, getState, { api }) => {
  const state = getState()
  if (state.Data.top_aired_fanarts.length > 0) return Promise.resolve()
  return new Promise(async (resolve, reject) => {
    try {
      await api.request({
        url: '/latest_schedule',
        method: 'GET',
        responseType: 'json',
        successCodes: [200]
      }, {
        fetchPolicy: 'cache-and-fetch',
        deduplicate: true
      }).then(data => {
        dispatch(setTopAired(data.top_aired_fanarts))
        resolve()
      }).catch(error => {
        reject(new Error(error))
      })
    } catch (err) {
      await handleError(err, dispatch, state, reject)
    }
  })
}

export const getPopularSeries = () => (dispatch, getState, { api }) => {
  const state = getState()
  if (state.Data.popular.length > 0) return Promise.resolve()
  return new Promise(async (resolve, reject) => {
    try {
      await api.request({
        url: '/popular',
        method: 'GET',
        responseType: 'json',
        successCodes: [200]
      }, {
        fetchPolicy: 'cache-and-fetch',
        deduplicate: true
      }).then(data => {
        dispatch(setPopularSeries(data))
        resolve()
      }).catch(error => {
        reject(new Error(error))
      })
    } catch (err) {
      await handleError(err, dispatch, state, reject)
    }
  })
}

export const getUpcoming = () => (dispatch, getState, { api }) => {
  const state = getState()
  if (state.Data.upcoming_premiere.length > 0) return Promise.resolve()
  return new Promise(async (resolve, reject) => {
    try {
      await api.request({
        url: '/upcoming',
        method: 'GET',
        responseType: 'json',
        successCodes: [200]
      }, {
        fetchPolicy: 'cache-and-fetch',
        deduplicate: true
      }).then(data => {
        dispatch(setUpcomingPremiere(data))
        resolve()
      }).catch(error => {
        reject(new Error(error))
      })
    } catch (err) {
      await handleError(err, dispatch, state, reject)
    }
  })
}

export const getSeriesInfo = (id) => (dispatch, getState, { api }) => {
  const state = getState()
  if (state.Data.series[id]) return Promise.resolve()
  return new Promise(async (resolve, reject) => {
    try {
      await api.request({
        url: `/series_info?id=${id}`,
        method: 'GET',
        responseType: 'json',
        successCodes: [200]
      }, {
        fetchPolicy: 'cache-and-fetch',
        deduplicate: true
      }).then(data => {
        dispatch(addSeries(id, data))
        resolve()
      }).catch(error => {
        reject(new Error(error))
      })
    } catch (err) {
      await handleError(err, dispatch, state, reject)
    }
  })
}

export const getSeriesEpisodes = (id) => (dispatch, getState, { api }) => {
  const state = getState()
  if (state.Data.episodes[id]) return Promise.resolve()
  return new Promise(async (resolve, reject) => {
    try {
      await api.request({
        url: `/episodes?id=${id}`,
        method: 'GET',
        responseType: 'json',
        successCodes: [200]
      }, {
        fetchPolicy: 'cache-and-fetch',
        deduplicate: true
      }).then(data => {
        dispatch(addEpisodes(id, data))
        resolve()
      }).catch(error => {
        reject(new Error(error))
      })
    } catch (err) {
      await handleError(err, dispatch, state, reject)
    }
  })
}

export const search = (q) => (dispatch, getState, { api }) => {
  const state = getState();
  return new Promise(async (resolve, reject) => {
    try {
      await api.request({
        url: `/simkl_search?q=${q}`,
        method: 'GET',
        responseType: 'json',
        successCodes: [200]
      }, {
        fetchPolicy: 'cache-and-fetch',
        deduplicate: true
      }).then(data => {
        batch(() => {
          dispatch(addCollectionBulk(0, data.map((d) => d)));
          dispatch(setSearchIds(data.map((d) => d.ids.simkl_id)));
        });
        resolve();
      }).catch(error => {
        reject(new Error(error));
      })
    } catch (err) {
      await handleError(err, dispatch, state, reject);
    }
  })
}

export const getAniListItem = (name, seriesId, idMal = null, force = false) => (dispatch, getState) => {
  const state = getState()
  if (state.Data.anilist[seriesId] && !force) return Promise.resolve(state.Data.anilist[seriesId])
  // if (!state.Auth.anilist.token) return Promise.reject(new Error('Not authenticated.'))
  const { token } = state.Auth.anilist
  return new Promise(async (resolve, reject) => {
    try {
      if (token) {
        if (idMal === null) {
          const resp = await axios.post('https://graphql.anilist.co', {
            query: `
            query ($name: String) {
              Media(search: $name, type: ANIME) {
                id
                idMal
                title {
                  romaji
                  english
                  native
                  userPreferred
                }
                description
                bannerImage
                tags {
                  id
                  name
                  description
                  rank
                  isMediaSpoiler
                  isGeneralSpoiler
                }
                coverImage {
                  color
                  medium
                  large
                  extraLarge
                }
                relations {
                  nodes {
                    id
                    idMal
                    title {
                      romaji
                      english
                      native
                      userPreferred
                    }
                  }
                }
                characters {
                  nodes {
                    name {
                      first
                      last
                      alternative
                      full
                    }
                    image {
                      large
                      medium
                    }
                  }
                }
                mediaListEntry {
                  progress
                }
                nextAiringEpisode {
                  id
                  airingAt
                  episode
                }
                externalLinks {
                  id
                  url
                  site
                }
              }
            }
          `,
            variables: {
              name
            }
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })

          const { data: { data: { Media } }, errors = [] } = resp
          if (errors.length) throw errors[0].message || 'An Error Occurred'

          if (!errors.length) {
            dispatch(addAniListItem(seriesId, Media))
            resolve(Media)
          }
        } else {
          const resp = await axios.post('https://graphql.anilist.co', {
            query: `
            query media($idMal: Int) {
              Media(idMal: $idMal, type: ANIME) {
                id
                idMal
                title {
                  userPreferred
                  romaji
                  english
                  native
                }
                coverImage {
                  extraLarge
                  large
                }
                bannerImage
                startDate {
                  year
                  month
                  day
                }
                endDate {
                  year
                  month
                  day
                }
                description
                season
                seasonYear
                type
                format
                status
                episodes
                duration
                chapters
                volumes
                genres
                synonyms
                source(version: 2)
                isAdult
                isLocked
                meanScore
                averageScore
                popularity
                favourites
                hashtag
                countryOfOrigin
                isLicensed
                isFavourite
                isRecommendationBlocked
                nextAiringEpisode {
                  airingAt
                  timeUntilAiring
                  episode
                }
                relations {
                  edges {
                    id
                    relationType(version: 2)
                    node {
                      id
                      title {
                        userPreferred
                      }
                      format
                      type
                      status
                      bannerImage
                      coverImage {
                        large
                      }
                    }
                  }
                }
                characterPreview: characters(perPage: 6, sort: [ROLE, ID]) {
                  edges {
                    id
                    role
                    voiceActors(language: JAPANESE) {
                      id
                      name {
                        full
                      }
                      language
                      image {
                        large
                      }
                    }
                    node {
                      id
                      name {
                        full
                      }
                      image {
                        large
                      }
                    }
                  }
                }
                staffPreview: staff(perPage: 8) {
                  edges {
                    id
                    role
                    node {
                      id
                      name {
                        full
                      }
                      language
                      image {
                        large
                      }
                    }
                  }
                }
                studios {
                  edges {
                    isMain
                    node {
                      id
                      name
                    }
                  }
                }
                reviewPreview: reviews(perPage: 2, sort: [RATING_DESC, ID]) {
                  pageInfo {
                    total
                  }
                  nodes {
                    id
                    summary
                    rating
                    ratingAmount
                    user {
                      id
                      name
                      avatar {
                        large
                      }
                    }
                  }
                }
                recommendations(perPage: 7, sort: [RATING_DESC, ID]) {
                  pageInfo {
                    total
                  }
                  nodes {
                    id
                    rating
                    userRating
                    mediaRecommendation {
                      id
                      title {
                        userPreferred
                      }
                      format
                      type
                      status
                      bannerImage
                      coverImage {
                        large
                      }
                    }
                    user {
                      id
                      name
                      avatar {
                        large
                      }
                    }
                  }
                }
                externalLinks {
                  site
                  url
                }
                streamingEpisodes {
                  site
                  title
                  thumbnail
                  url
                }
                trailer {
                  id
                  site
                }
                rankings {
                  id
                  rank
                  type
                  format
                  year
                  season
                  allTime
                  context
                }
                tags {
                  id
                  name
                  description
                  rank
                  isMediaSpoiler
                  isGeneralSpoiler
                }
                mediaListEntry {
                  id
                  status
                  score
                }
                stats {
                  statusDistribution {
                    status
                    amount
                  }
                  scoreDistribution {
                    score
                    amount
                  }
                }
              }
            }

          `,
            variables: {
              idMal
            }
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })

          const { data: { data: { Media } }, errors = [] } = resp
          if (errors.length) throw errors[0].message || 'An Error Occurred'

          if (!errors.length) {
            dispatch(addAniListItem(seriesId, Media))
            resolve(Media)
          }
        }
      } else {
        if (idMal === null) {
          const resp = await axios.post('https://graphql.anilist.co', {
            query: `
            query ($name: String) {
              Media(search: $name, type: ANIME) {
                id
                idMal
                title {
                  romaji
                  english
                  native
                  userPreferred
                }
                description
                bannerImage
                coverImage {
                  color
                  medium
                  large
                  extraLarge
                }
                relations {
                  nodes {
                    id
                    idMal
                    title {
                      romaji
                      english
                      native
                      userPreferred
                    }
                  }
                }
                characters {
                  nodes {
                    name {
                      first
                      last
                      alternative
                      full
                    }
                    image {
                      large
                      medium
                    }
                  }
                }
                mediaListEntry {
                  progress
                }
                nextAiringEpisode {
                  id
                  airingAt
                  episode
                }
                externalLinks {
                  id
                  url
                  site
                }
              }
            }
          `,
            variables: {
              name
            }
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          })

          const { data: { data: { Media } }, errors = [] } = resp
          if (errors.length) throw errors[0].message || 'An Error Occurred'

          if (!errors.length) {
            dispatch(addAniListItem(seriesId, Media))
            resolve(Media)
          }
        } else {
          const resp = await axios.post('https://graphql.anilist.co', {
            query: `
            query media($idMal: Int) {
              Media(idMal: $idMal, type: ANIME) {
                id
                idMal
                title {
                  userPreferred
                  romaji
                  english
                  native
                }
                coverImage {
                  extraLarge
                  large
                }
                bannerImage
                startDate {
                  year
                  month
                  day
                }
                endDate {
                  year
                  month
                  day
                }
                description
                season
                seasonYear
                type
                format
                status
                episodes
                duration
                chapters
                volumes
                genres
                synonyms
                source(version: 2)
                isAdult
                isLocked
                meanScore
                averageScore
                popularity
                favourites
                hashtag
                countryOfOrigin
                isLicensed
                isFavourite
                isRecommendationBlocked
                nextAiringEpisode {
                  airingAt
                  timeUntilAiring
                  episode
                }
                relations {
                  edges {
                    id
                    relationType(version: 2)
                    node {
                      id
                      title {
                        userPreferred
                      }
                      format
                      type
                      status
                      bannerImage
                      coverImage {
                        large
                      }
                    }
                  }
                }
                characterPreview: characters(perPage: 6, sort: [ROLE, ID]) {
                  edges {
                    id
                    role
                    voiceActors(language: JAPANESE) {
                      id
                      name {
                        full
                      }
                      language
                      image {
                        large
                      }
                    }
                    node {
                      id
                      name {
                        full
                      }
                      image {
                        large
                      }
                    }
                  }
                }
                staffPreview: staff(perPage: 8) {
                  edges {
                    id
                    role
                    node {
                      id
                      name {
                        full
                      }
                      language
                      image {
                        large
                      }
                    }
                  }
                }
                studios {
                  edges {
                    isMain
                    node {
                      id
                      name
                    }
                  }
                }
                reviewPreview: reviews(perPage: 2, sort: [RATING_DESC, ID]) {
                  pageInfo {
                    total
                  }
                  nodes {
                    id
                    summary
                    rating
                    ratingAmount
                    user {
                      id
                      name
                      avatar {
                        large
                      }
                    }
                  }
                }
                recommendations(perPage: 7, sort: [RATING_DESC, ID]) {
                  pageInfo {
                    total
                  }
                  nodes {
                    id
                    rating
                    userRating
                    mediaRecommendation {
                      id
                      title {
                        userPreferred
                      }
                      format
                      type
                      status
                      bannerImage
                      coverImage {
                        large
                      }
                    }
                    user {
                      id
                      name
                      avatar {
                        large
                      }
                    }
                  }
                }
                externalLinks {
                  site
                  url
                }
                streamingEpisodes {
                  site
                  title
                  thumbnail
                  url
                }
                trailer {
                  id
                  site
                }
                rankings {
                  id
                  rank
                  type
                  format
                  year
                  season
                  allTime
                  context
                }
                tags {
                  id
                  name
                  description
                  rank
                  isMediaSpoiler
                  isGeneralSpoiler
                }
                mediaListEntry {
                  id
                  status
                  score
                }
                stats {
                  statusDistribution {
                    status
                    amount
                  }
                  scoreDistribution {
                    score
                    amount
                  }
                }
              }
            }
          `,
            variables: {
              idMal
            }
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          })

          const { data: { data: { Media } }, errors = [] } = resp
          if (errors.length) throw errors[0].message || 'An Error Occurred'

          if (!errors.length) {
            dispatch(addAniListItem(seriesId, Media))
            resolve(Media)
          }
        }
      }
    } catch (err) {
      reject(err)
    }
  })
}
