import axios from 'axios';
import uniqid from 'uniqid';
import service from '../lib/services';
import { handleError, setError } from './Data';
import { batch } from 'react-redux';

export const UPDATE_AUTH = 'UPDATE_AUTH'
export const updateAuth = (authData) => ({
  type: UPDATE_AUTH,
  payload: authData
})
export const removeSIMKL = () => updateAuth({username: '', token: '', guest: true, avatarURL: '', timezone: 'America/New_York'})

export const REMOVE_AUTH = 'REMOVE_AUTH'
export const removeAuth = () => ({
  type: REMOVE_AUTH
})

export const SET_EXPIRED_SESSION = 'SET_EXPIRED_SESSION'
export const setExpiredSession = (payload) => ({
  type: SET_EXPIRED_SESSION,
  payload
})

export const UPDATE_DEVICE_INFO = 'UPDATE_DEVICE_INFO'
export const updateClientDevice = (device_type, device_os, device_client) => ({
  type: UPDATE_DEVICE_INFO,
  payload: {
    device_type,
    device_os,
    device_client
  }
})
export const removeClientDevice = () => updateClientDevice('')

export const UPDATE_KITSU = 'UPDATE_KITSU'
export const updateKitsu = (username, token) => ({
  type: UPDATE_KITSU,
  payload: {
    username,
    token
  }
})
export const removeKitsu = () => updateKitsu('', '')

export const UPDATE_ANILIST = 'UPDATE_ANILIST'
export const updateAniList = (username, token, avatarURL, other_data) => ({
  type: UPDATE_ANILIST,
  payload: {
    username,
    token,
    avatarURL,
    other_data
  }
})
export const removeAniList = () => updateAniList('', '', '', '')

export const UPDATE_SIMKL = 'UPDATE_SIMKL'
export const updateSimkl = (username, token, avatarURL, guest) => ({
  type: UPDATE_SIMKL,
  payload: {
    username,
    token,
    avatarURL,
    guest
  }
})
export const removeSimkl = () => updateSimkl('', '', '', true)

export const startSession = () => (dispatch, getState) => {
  const state = getState()
  const params = {
    device_id: state.Auth.uuid
  }

  if (state.Auth.token) {
    params.auth = state.Auth.token
  }

  return new Promise(async (resolve, reject) => {
    try {
      const resp = await service({route: 'session_start', params, locale: state.Options.language, noCancel: true})
      const data = resp.data.device
      batch(() => {
        dispatch(updateAuth({
          session_id: uniqid(),
          country: resp.data.locale.country_name,
          ip_address: resp.data.locale.ip,
          client_id: resp.data.device.client.name + " " + resp.data.device.client.version + " (" + resp.data.device.client.type + ")",
          os_name: resp.data.device.os.name + " " + resp.data.device.os.version
        }))
        dispatch(updateClientDevice({
          device_type: resp.data.device.device.type,
          device_os: resp.data.device.os.name + " " + resp.data.device.os.version + " (" + resp.data.device.os.platform + ")",
          device_client: resp.data.device.client.name + " " + resp.data.device.client.version + " (" + resp.data.device.client.type + ")"
        }))
      })
      resolve(data.session_id)
    } catch (err) {
      await handleError(err, dispatch, state, reject)
    }
  })
}

export const register = (username, password) => (dispatch, getState) => {
  const state = getState();
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await axios({
        method: 'POST',
        url: 'https://accounts.animetv.rocks/auth/register',
        data: ({username: `${username}`, password: `${password}`}),
        headers: {
          'Access-Control-Allow-Origin' : '*'
        }
      })
      if (resp.data.error) throw resp
      dispatch(updateAuth({ username: resp.data.username }));
      if (state.Data.error === 'bad_request') dispatch(setError(''))
      resolve();
    } catch (err) {
        await handleError(err, dispatch, state, reject)
    }
  })
}

export const login = (username, password) => (dispatch, getState) => {
  const state = getState();
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await axios({
        method: 'POST',
        url: 'https://accounts.animetv.rocks/auth/login',
        data: ({username: `${username}`, password: `${password}`}),
        headers: {
          'Access-Control-Allow-Origin' : '*'
        }
      })
      if (resp.data.error) {
        throw resp
      } else {
        // Update session_uuid
        await axios({
          method: 'PUT',
          url: 'https://accounts.animetv.rocks/auth/user/session_id',
          data: ({
            session_uuid: `${state.Auth.uuid}`,
            country: `${state.Auth.country}`,
            client_id: `${state.Auth.client_id}`,
            os_name: `${state.Auth.os_name}`,
            ip_address: `${state.Auth.ip_address}`
          }),
          headers: {
            'Access-Control-Allow-Origin' : '*',
            'access-token': `${resp.data.token}`
          }
        })
      }
      dispatch(updateAuth({
        username: resp.data.username,
        userID: resp.data._id,
        token: resp.data.token,
        avatarURL: resp.data.avatar_url,
        guest: false,
        expires: new Date(new Date().setDate(new Date().getDate() + 30)).getTime(),
        expiredSession: ""
      }));
      if (state.Data.error === 'bad_request') dispatch(setError(''))
      resolve();
    } catch (err) {
        await handleError(err, dispatch, state, reject)
    }
  })
}

export const checkSIMKLToken = (token) => (dispatch, getState, {api}) => {
  const state = getState()
  return new Promise(async (resolve, reject) => {
    try {
      await api.request({
        url: `/user_settings`,
        method: 'POST',
        body: JSON.stringify({access_token: `${token}`}),
        responseType: 'json',
        successCodes: [200]
      }, {
        fetchPolicy: 'cache-and-fetch',
        deduplicate: true
      }).then(data => {
        batch(() => {
          dispatch(updateAuth({timezone: data.account.timezone}));
          dispatch(updateSimkl(data.user.name, token, data.user.avatar, false));
        });
        resolve();
      }).catch(error => {
        reject(new Error(error));
      })
    } catch (err) {
        await handleError(err, dispatch, state, reject)
    }
  })
}

export const checkAniListToken = (token) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await axios.post('https://graphql.anilist.co', {
        query: `
          query {
            Viewer {
              id
              name
              updatedAt
              siteUrl
              about
              bannerImage
              isFollowing
              isFollower
              donatorTier
              donatorBadge
              moderatorStatus
              isBlocked
              bans
              avatar {
                large
                medium
              }
              options {
                displayAdultContent
                profileColor
                airingNotifications
              }
              statistics {
                anime {
                  count
                  meanScore
                  standardDeviation
                  minutesWatched
                  episodesWatched
                  genrePreview: genres(limit: 5) {
                    genre
                    count
                  }
                }
              }
              favourites {
                anime {
                  edges {
                    favouriteOrder
                    node {
                      id
                      type
                      status
                      format
                      bannerImage
                      title {
                        userPreferred
                      }
                      coverImage {
                        large
                      }
                      startDate {
                        year
                      }
                    }
                  }
                }
                manga {
                  edges {
                    favouriteOrder
                    node {
                      id
                      type
                      status
                      format
                      bannerImage
                      title {
                        userPreferred
                      }
                      coverImage {
                        large
                      }
                      startDate {
                        year
                      }
                    }
                  }
                }
                characters {
                  edges {
                    favouriteOrder
                    node {
                      id
                      name {
                        full
                      }
                      image {
                        large
                      }
                    }
                  }
                }
                staff {
                  edges {
                    favouriteOrder
                    node {
                      id
                      name {
                        full
                      }
                      image {
                        large
                      }
                    }
                  }
                }
                studios {
                  edges {
                    favouriteOrder
                    node {
                      id
                      name
                    }
                  }
                }
              }
              mediaListOptions {
                scoreFormat
              }
            }
          }
        `
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      const { data: { data: { Viewer } }, errors = [] } = data
      if (errors.length) throw errors[0].message || 'An Error Occurred'

      if (!errors.length) {
        batch(() => {
          dispatch(updateAniList(Viewer.name, token, Viewer.avatar.medium, Viewer));
        });
        resolve();
      }
    } catch (err) {
      reject(err)
    }
  })
}
