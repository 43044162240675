import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { inject } from '@vercel/analytics';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { ApiProvider } from 'fairlight';
import toast, { Toaster } from 'react-hot-toast';
export const { store, persistor, history, api } = configureStore()

render(
  <Suspense fallback={<></>}>
    <Provider store={store}>
      <ApiProvider api={api}
        defaults={{
          useApiQuery: {
            fetchPolicy: 'cache-and-fetch'
          },
          useApiMutation: {
            fetchPolicy: 'cache-and-fetch'
          }
        }}>
        <PersistGate persistor={persistor}>
          <ConnectedRouter history={history}>
            <Toaster
              position='bottom-left'
              toastOptions={{
                duration: 8000,
                style: {
                  background: '#333',
                  color: '#fff',
                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', // Box shadow for the toast
                }
              }} />
            <App />
          </ConnectedRouter>
        </PersistGate>
      </ApiProvider>
    </Provider>
  </Suspense>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate() {
    toast.custom(() => (
      <div style={{
        background: '#333',
        color: '#fff',
        padding: '1rem',
        borderRadius: '0.5rem',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
      }}>
        <p style={{ margin: 0 }}>A new version of AnimeTV is available! 🎉</p>
        <button style={{
          background: '#242628',
          color: '#fff',
          padding: '0.5rem 1rem',
          borderRadius: '0.5rem',
          border: 'none',
          cursor: 'pointer',
          marginTop: '0.5rem',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
        }} className='btn btn-dark w-100' onClick={() => window.location.reload()}>Refresh</button>
      </div>
    ), {
      id: 'update',
      duration: Infinity,
      position: 'bottom-left'
    });
  }
});

inject(); // Analytics for Vercel
