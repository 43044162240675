import { store as reduxStore } from '../index';

export const formatTime = (secs, isMinutes) => {
  if (!isMinutes) {
    const minutes = Math.floor(secs / 60)
    let seconds = Math.floor(secs - (minutes * 60))
    seconds = seconds < 10 ? `0${seconds}` : seconds
    return `${minutes}:${seconds}`
  } else {
    return `${secs}:00`
  }
}

export const generateDigitPassword = () => {
  // Random length between 8 and 12
  const length = Math.floor(Math.random() * 4) + 8
  return Math.floor(Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1))
}

export const isFullscreen = () => !!(
  document.webkitFullscreenElement ||
  document.webkitIsFullScreen ||
  document.mozFullScreen ||
  document.msFullscreenElement
)

export function isLoggedIn () {
  const store = reduxStore.getState()
  return !store.Auth.guest &&
    store.Auth.token &&
    store.Auth.username &&
    !store.Auth.expiredSession &&
    store.Auth.expires &&
    new Date() < new Date(store.Auth.expires)
}

export class DoubleEventHandler {
  constructor(delay = 500) {
    this.delay = delay
    this.lastTime = 0
  }

  handle(event, cb, prevented = true) {
    // Based on http://jsfiddle.net/brettwp/J4djY/
    let currentTime = new Date().getTime()
    let diffTime = currentTime - this.lastTime

    if (diffTime < this.delay && diffTime > 0) {
      cb()
      prevented && event.preventDefault()
    }

    this.lastTime = currentTime
  }
}

/**
 * Returns an promise of fetched API data,
 * which allows custom handlers to resolve
 * the promise and use their own error handlers
 * to catch any network errors that may occur.
 *
 * @param {String} url
 * @param {Array} header
 * @returns {Promise} Unresolved promise data
 *
 */
export async function getRequest(url, header) {
  let data = await (await (fetch(url, header)
    .then(response => {
      return response.json()
    })
  ))

  return data
}
