import { v4 as uuid } from "uuid";
import {
  UPDATE_AUTH,
  SET_EXPIRED_SESSION,
  REMOVE_AUTH,
  UPDATE_DEVICE_INFO,
  UPDATE_SIMKL,
  UPDATE_KITSU,
  UPDATE_ANILIST,
} from "../actions";

// make UUIDs noticeable
const genUUID = () => {
  let id = uuid();
  const parts = id.split("-");
  parts[1] = "ANIMETV";
  return parts.join("-");
};

const initialState = {
  token: "",
  expires: 8640000000000000,
  username: "",
  userID: "",
  avatarURL: "",
  timezone: "America/New_York",
  guest: true,
  premium: false,
  device: { device_type: "", device_os: "", device_client: "" },
  simkl: { username: "", token: "", avatarURL: "", guest: true },
  kitsu: { username: "", token: "", avatarURL: "" },
  anilist: { username: "", token: "", avatarURL: "" },
  expiredSession: "",
  session_id: "",
  country: "",
  ip_address: "",
  client_id: "",
  os_name: "",
  uuid: genUUID(),
};

export default function Auth(state = initialState, action) {
  switch (action.type) {
    case UPDATE_AUTH:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_DEVICE_INFO:
      return {
        ...state,
        device: action.payload,
      };
    case UPDATE_SIMKL:
      return {
        ...state,
        simkl: action.payload,
      };
    case UPDATE_KITSU:
      return {
        ...state,
        kitsu: action.payload,
      };
    case UPDATE_ANILIST:
      return {
        ...state,
        anilist: action.payload,
      };
    case REMOVE_AUTH:
      return {
        ...initialState,
        expiredSession: state.expiredSession,
        uuid: state.uuid,
        kitsu: state.kitsu,
        anilist: state.anilist,
      };
    case SET_EXPIRED_SESSION:
      return {
        ...state,
        expiredSession: action.payload,
      };
    default:
      return state;
  }
}
