import {
  HIDE_ABOUT,
  SET_LANGUAGE,
  TOGGLE_AUTOPLAY,
  TOGGLE_ORDER_CONTROLS,
  SET_THEME,
  TOGGLE_AUTO_THEME,
  SET_HOMEPAGE_CONTINUE_COUNT,
  TOGGLE_ANNOUNCEMENT_ALERT,
  TOGGLE_FEATURED_ANIME,
  TOGGLE_USE_ENGLISH_TITLE,
  TOGGLE_PLAYER_ERROR_ALERT,
} from "../actions";

export default function Options(
  state = {
    language: "enUS",
    autoplay: true,
    aboutVisible: false,
    orderControls: true,
    theme: "light",
    autoThemeChange: true,
    homepageContinueCount: 4,
    showAnnouncementAlert: false,
    showFeaturedAnimeCarousel: false,
    useEnglishTitle: true,
    showPlayerError: false,
  },
  action
) {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case TOGGLE_AUTOPLAY:
      return {
        ...state,
        autoplay: !state.autoplay,
      };
    case HIDE_ABOUT:
      return {
        ...state,
        aboutVisible: false,
      };
    case TOGGLE_ORDER_CONTROLS:
      return {
        ...state,
        orderControls: !state.orderControls,
      };
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case TOGGLE_AUTO_THEME:
      return {
        ...state,
        autoThemeChange: !state.autoThemeChange,
      };
    case SET_HOMEPAGE_CONTINUE_COUNT:
      return {
        ...state,
        homepageContinueCount: Number(action.payload),
      };
    case TOGGLE_ANNOUNCEMENT_ALERT:
      return {
        ...state,
        showAnnouncementAlert: !state.showAnnouncementAlert,
      };
    case TOGGLE_FEATURED_ANIME:
      return {
        ...state,
        showFeaturedAnimeCarousel: !state.showFeaturedAnimeCarousel,
      };
    case TOGGLE_USE_ENGLISH_TITLE:
      return {
        ...state,
        useEnglishTitle: !state.useEnglishTitle,
      };
    case TOGGLE_PLAYER_ERROR_ALERT:
      return {
        ...state,
        showPlayerError: !state.showPlayerError,
      };
    default:
      return state;
  }
}
